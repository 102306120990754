import React, { useContext } from "react";
import { Header } from "./Header";
import { AppContext } from "../App";
import { Testimonials } from "./Testimonials";
import { Team } from "./Team";

export const Home = (props) => {
  let { landingPageData } = useContext(AppContext);
  return (
    <div>
      <Header data={landingPageData.Header} headerClass="intro" />
      <div className="text-center">
        <div className="container-fluid">
          <div className="section-title">
            <h2>Mission Statement</h2>
          </div>
          <div className="container-fluid">
            Empowering disadvantaged youths in Zimbabwe through accessible, affordable, and innovative technical skills development programs, fostering emotional, spiritual, and psycho-social growth to transform their lives and communities.
          </div>
        </div>
        <div className="row">
          <div className="col-md-6">
            <div className="container-fluid card">
              <h2 className="text-center">Core Values</h2>
              <div className="list">
                <ol>
                  <li>Compassion: We care for the well-being and dignity of our beneficiaries.</li>
                  <li>Innovation: We embrace cutting-edge technology and creative solutions.</li>
                  <li>Accessibility: We bridge the gap for the less fortunate, making education and skills attainable.</li>
                  <li>Excellence: We strive for quality in our programs, services, and relationships.</li>
                  <li>
                    Empowerment: We nurture self-reliance, confidence, and resilience in our beneficiaries.
                  </li>
                </ol>
              </div>
            </div>
          </div>
          <div className="col-md-6">
            <div className="container-fluid card">
              <h2 className="text-center">Goals</h2>
              <div className="list">
                <ol>
                  <li>Provide affordable technical skills training to 1,000 disadvantaged youths annually.</li>
                  <li>Establish partnerships with 10 local organizations and 5 international institutions by 2025.</li>
                  <li>Achieve 80% employment or entrepreneurship rate among graduates within 6 months.</li>
                  <li>Expand operations to 5 rural areas and 3 urban centers by 2027.</li>
                </ol>
              </div>
            </div>
          </div>
          <div className="col-md-12 col-xl-12 py-2">
            <h2 className="text-center">Concept Summary</h2>
            <div className="container-fluid">
              The Zimbabwe Innovation and Technology Development Trust (ZITDT) is a non-profit organization established in 2014 to empower disadvantaged youths in Zimbabwe through technical skills development programs, emotional support, and psycho-social care. ZITDT aims to bridge the gap in access to education and skills training, fostering self-reliance and resilience in its beneficiaries.
            </div>
          </div>
          <div className="col-md-12 col-xl-12 py-2">
            <h2 className="text-center">Vision Statement</h2>
            <div className="container-fluid">
              A Zimbabwe where all youths, regardless of socio-economic background, have access to quality education, skills training, and opportunities for personal growth and development.
            </div>
          </div>
        </div>
      </div>
      <Testimonials data={landingPageData.Testimonials} />
      <Team data={landingPageData.Team} />
    </div>
  );
};
