import React from 'react'
import { Header } from '../../Header';

export const Course = (props) => {
    return (
        <div>
            {props.data && <div><Header data={props.data} headerClass="courses-header header-custom-text" /><div className="container-fluid">
                <div className='my-4 py-4 text-center'>{props.data.paragraph}</div>
            <div>
                <h3>Course Outcomes</h3>
                <ul>
                {props.data && props.data.CourseOutcomes.map((x, y) => {
                                return <li key={`${x}-${y}`} className='list-group-item'>{x}</li>;
                            })}
                </ul>
            </div>
                <div className="list-style">
                    <div className="container-fluid">
                        <div className="panel panel-default">
                        </div>
                        <div className="panel">
                            <h3 className='panel-heading'>Advantages/Reasons to Take</h3>
                            {props.data && props.data.advantages.map((x, y) => {
                                return <li key={`${x}-${y}`} className='list-group-item'>{x}</li>;
                            })}
                        </div>
                        <div className="panel">
                            <h3 className='panel-heading'>Job Opportunities</h3>
                            {props.data && props.data.opportunities.map((x, y) => {
                                return <li key={`${x}-${y}`} className='list-group-item'>{x}</li>;
                            })}
                        </div>
                        <div className="panel">
                            <h3 className='panel-heading'>Community/National Benefits</h3>
                            {props.data && props.data.benefits.map((x, y) => {
                                return <li key={`${x}-${y}`} className='list-group-item'>{x}</li>;
                            })}
                        </div>
                    </div>
                </div>
                <div>
                <h3>Join the Course and</h3>
                <ul>
                {props.data && props.data.JoinTheCourseAnd.map((x, y) => {
                                return <li key={`${x}-${y}`} className='list-group-item'>{x}</li>;
                            })}
                </ul>
            </div>
            </div></div>}
        </div>
    )
}
