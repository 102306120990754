import React from 'react'

export const Footer = () => {
    return (
        <div id="footer">
            <div className="container text-center">
                <p>
                    &copy; {new Date().getFullYear()} ZITDT
                    {/* . Design by{" "}
                    <a href="https://malvernbright.co.zw" rel="nofollow">
                        ByteSphere Dev
                    </a> */}
                </p>
            </div>
        </div>
    )
}
