import React from "react";
import { Link } from "react-router-dom";
import logo from "../img/logo.png"


export const Navigation = (props) => {
  return (
    <nav id="menu" className="navbar navbar-default navbar-fixed-top">
      <div className="container">
        <div className="navbar-header">
          <button
            type="button"
            className="navbar-toggle collapsed"
            data-toggle="collapse"
            data-target="#bs-example-navbar-collapse-1"
          >
            {" "}
            <span className="sr-only">Toggle navigation</span>{" "}
            <span className="icon-bar"></span>{" "}
            <span className="icon-bar"></span>{" "}
            <span className="icon-bar"></span>{" "}
          </button>
          <Link className="navbar-brand page-scroll" to="/">
            <img src={logo} height={50} width={50} alt="ZITDT Logo"/>
          </Link>{" "}
        </div>

        <div
          className="collapse navbar-collapse"
          id="bs-example-navbar-collapse-1">
          <ul className="nav navbar-nav navbar-right">

            <li>
              <Link to="/about">
                About
              </Link>
            </li>
            {/* <li>
              <Link to="/services">
                Services
              </Link>
            </li> */}
            <li>
              <Link to="/gallery">
                Gallery
              </Link>
            </li>
            <li className="dropdown">
              <Link className="nav-link dropdown-toggle" to="#" id="navbarDropdown" role="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                Courses &nbsp;<span className="caret"></span>
              </Link>
              <ul className="dropdown-menu">
                <li><Link to="/computer-engineering">Computer Engineering</Link></li>
                <li><Link to="/electronic-engineering">Electronic Engineering</Link></li>
                <li><Link to="/android-dev">Android Development</Link></li>
              </ul>
            </li>
            <li>
              <Link to="/register">Register</Link>
            </li>
            <li>
              <Link to="/contact">
                Contact
              </Link>
            </li>
          </ul>
        </div>
      </div>
    </nav>
  );
};
