import React, { useContext } from "react";
import { Header } from "./Header";
import { AppContext } from "../App";

export const About = (props) => {
  let { landingPageData } = useContext(AppContext);
  return (
    <div>
      <Header data={landingPageData.Header} headerClass="page-header about-header header-custom-text" />
      <div id="about">
        <div className="container">
          <div className="row">
            <div className="col-xs-12 col-md-6">
              {" "}
              <img src="img/about.jpg" className="img-responsive" alt="" />{" "}
            </div>
            <div className="col-xs-12 col-md-6">
              <div className="about-text">
                <h2>Why Choose ZITDT</h2>
                <p>{props.data ? props.data.paragraph : "loading..."}</p>

                <h4>Key Course Benefits</h4>
                <div className="list-style">
                  <div className="col-lg-8 col-sm-8 col-xs-12">
                    <ul>
                      {props.data
                        ? props.data.Why.map((d, i) => (
                          <li key={`${d}-${i}`}>{d}</li>
                        ))
                        : "loading"}
                    </ul>
                  </div>
                  {/* <div className="col-lg-6 col-sm-6 col-xs-12">
      <ul>
        {props.data
          ? props.data.Why2.map((d, i) => (
              <li key={`${d}-${i}`}> {d}</li>
            ))
          : "loading"}
      </ul>
    </div> */}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div></div>
  );
};
