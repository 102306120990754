import React from 'react'

export const NotFound = () => {
    return (
        <div>
            <h2>OOPS</h2>
            <div className="container-fluid">

                <i class="fa fa-binoculars" aria-hidden="true"></i>
                404 NotFound
            </div>
        </div>
    )
}
