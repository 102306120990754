import React, { useState, useEffect, createContext } from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import { Navigation } from "./components/Navigation";
import { Home } from "./components/Home";
import { About } from "./components/About";
import { Services } from "./components/Services";
import { Gallery } from "./components/Gallery";
import { Contact } from "./components/Contact";
import {Register} from "./components/Register";
import JsonData from "./data/data.json";
import SmoothScroll from "smooth-scroll";
import "./App.css";
import { NotFound } from "./components/NotFound";
import { ComputerEngineering } from "./components/courses/ComputerEngineering";
import { Footer } from "./components/Footer";
import { ElectronicEngineering } from "./components/courses/ElectronicEngineering";
import { AndroidApplicationDevelopment } from "./components/courses/AndroidApplicationDevelopment";


export const scroll = new SmoothScroll('a[href*="#"]', {
  speed: 1000,
  speedAsDuration: true,
});

export const AppContext = createContext();

const App = () => {
  const [landingPageData, setLandingPageData] = useState({});
  useEffect(() => {
    setLandingPageData(JsonData);
  }, []);
  return (
    <div>
      <AppContext.Provider value={{ landingPageData, setLandingPageData }}>
        <Router>
          <Navigation />
          <Routes>
            <Route path="/" element={<Home data={landingPageData.Features} />} />
            <Route path="/about" element={<About data={landingPageData.About} />} />
            <Route path="/services" element={<Services data={landingPageData.Services} />} />
            <Route path="/gallery" element={<Gallery data={landingPageData.Gallery} />} />
            <Route path="/contact" element={<Contact data={landingPageData.Contact} />} />
            <Route path="/register" element={<Register data={landingPageData.Register} />} />
            {/* <Route path="/courses" element={<Courses data={landingPageData.Courses}/>} /> */}
            <Route path="/computer-engineering" element={<ComputerEngineering data={landingPageData.ComputerEngineering} />} />
            <Route path="/electronic-engineering" element={<ElectronicEngineering data={landingPageData.ElectronicEngineering} />} />
            <Route path="/android-dev" element={<AndroidApplicationDevelopment data={landingPageData.AndroidApplicationDevelopment} />} />
            <Route path="*" element={<NotFound />} />
          </Routes>
          <Footer />
        </Router>
      </AppContext.Provider>
    </div>
  );
};

export default App;
