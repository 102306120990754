import { useState } from "react";
import emailjs from "emailjs-com";
import React from "react";
import { Header } from "./Header";

const initialState = {
  firstName: "",
  surname: "",
  dob: "",
  gender: "",
  phone: "",
  resAddress: "",
  language: "",
};
export const Register = (props) => {
  const [{ firstName, surname, dob, gender, phone, resAddress, language }, setState] = useState(initialState);

  const handleChange = (e) => {
    const { firstName, surname, dob, gender, phone, resAddress, language, value } = e.target;
    setState((prevState) => ({ ...prevState, [firstName]: value }));
    setState((prevState) => ({ ...prevState, [surname]: value }));
    setState((prevState) => ({ ...prevState, [dob]: value }));
    setState((prevState) => ({ ...prevState, [gender]: value }));
    setState((prevState) => ({ ...prevState, [phone]: value }));
    setState((prevState) => ({ ...prevState, [resAddress]: value }));
    setState((prevState) => ({ ...prevState, [language]: value }));
  };
  const clearState = () => setState({ ...initialState });

  const handleSubmit = (e) => {
    e.preventDefault();
    console.log(firstName, surname, dob, gender, phone, resAddress, language);
    emailjs
      .sendForm("service_ljcr42x", "template_zb1jbea", e.target, "W6Jrt7wvzW-RheKS0")
      .then(
        (result) => {
          console.log(result.text);
          clearState();
        },
        (error) => {
          console.log(error.text);
        }
      );
  };
  return (
    <div>
      {props.data && <Header data={props.data} headerClass="courses-header header-custom-text" />}
      <div id="contact">
        <div className="container">
          <div className="col-md-8">
            <div className="row">
              <div className="section-title">
                <h2>Register Here</h2>
                <p>
                  Please fill out the form below to send us an email and we will
                  get back to you as soon as possible.
                </p>
              </div>
              <form name="sentMessage" validate onSubmit={handleSubmit}>
                <div className="row">
                  <div className="col-md-6">
                    <div className="form-group">
                      <label htmlFor="firstName">First Name</label>
                      <input
                        type="text"
                        id="firstName"
                        name="firstName"
                        className="form-control"
                        placeholder="First Name"
                        required
                        onChange={handleChange}
                      />
                      <p className="help-block text-danger"></p>
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="form-group">
                      <label htmlFor="surname">Surname</label>
                      <input
                        type="text"
                        id="surname"
                        name="surname"
                        className="form-control"
                        placeholder="Surname"
                        required
                        onChange={handleChange}
                      />
                      <p className="help-block text-danger"></p>
                    </div>
                  </div>
                </div>
                <div className="row">
                  <div className="col-md-6">
                    <div className="form-group">
                      <label>Date of birth</label>
                      <input
                        type="date"
                        id="dob"
                        name="dob"
                        className="form-control"
                        max="2020-31-12"
                        placeholder="Date of birth"
                        required
                        onChange={handleChange}
                      />
                      <p className="help-block text-danger"></p>
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="form-group">
                      <label htmlFor="gender">Gender</label>
                      <select name="gender" className="form-control form-select">
                        {props.data
                          ? props.data.gender.map((d, i) => (
                            <option key={`${d}-${i}`} value={d}>{d}</option>
                          ))
                          : "loading"}
                      </select>
                      <p className="help-block text-danger"></p>
                    </div>
                  </div>
                </div>
                <div className="row">
                  <div className="col-md-6">
                    <div className="form-group">
                      <label>Phone</label>
                      <input
                        type="phone"
                        id="phone"
                        name="phone"
                        className="form-control"
                        placeholder="Phone"
                        required
                        onChange={handleChange}
                      />
                      <p className="help-block text-danger"></p>
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="form-group">
                      <label htmlFor="language">Language</label>
                      <select name="language" className="form-control form-select">
                        {props.data
                          ? props.data.language.map((d, i) => (
                            <option key={`${d}-${i}`} value={d}>{d}</option>
                          ))
                          : "loading"}
                      </select>
                      <p className="help-block text-danger"></p>
                    </div>
                  </div>
                </div>
                <div className="form-group">
                  <textarea
                    name="resAddress"
                    id="resAddress"
                    className="form-control"
                    rows="4"
                    placeholder="Residential Address"
                    required
                    onChange={handleChange}
                  ></textarea>
                  <p className="help-block text-danger"></p>
                </div>
                <div id="success"></div>
                <button type="submit" className="btn btn-custom btn-lg">
                  Register
                </button>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};