import { Header } from "./Header";
import { Image } from "./image";
import React from "react";

export const Gallery = (props) => {
  return (
    <div>
      {props.data && <><Header data={props.data} headerClass="courses-header header-custom-text" />
        <div id="portfolio" className="text-center">
          <div className="container">
            <div className="section-title">
              <h2>Gallery</h2>
              <p>
                {props.data.paragraph}
              </p>
            </div>
            <div className="row">
              <div className="portfolio-items">
                {props.data
                  ? props.data.gallery.map((d, i) => (
                    <div
                      key={`${d.title}-${i}`}
                      className="col-sm-6 col-md-4 col-lg-4"
                    >
                      <Image
                        title={d.title}
                        largeImage={d.largeImage}
                        smallImage={d.smallImage}
                      />
                    </div>
                  ))
                  : "Loading..."}
              </div>
            </div>
          </div>
        </div></>

      }
    </div>
  );
}

